// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'name_en',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'phone_number_code',
    Object: 'value',
    label: '',
    width: '150'
  }, {
    prop: 'code2',
    label: '',
    Object: 'value',
    width: '100',
  }, {
    prop: 'remote_flag_icon_url',
    label: '',
    width: '150',
    img: true
  }]
}
